export const DOMAIN = 'https://store-api.bikiran.com/manage';
export const PUBLIC_DOMAIN = 'https://store-api.bikiran.com';

const ConfigApi = {
    API_APP_INIT: `${DOMAIN}/init/:shopId/info/`,
    API_SITE_DATA: `${DOMAIN}/site-data.json`,

    // Login Page
    API_LOGGING_IN: `${DOMAIN}/login/`,

    // --Shop Info
    API_SHOP_INFO: `${DOMAIN}/shop/:shopId/info`,

    // Product Module

    API_PRODUCTS: `${DOMAIN}/products/list/`,

    // API_PRODUCT_VARIATION_LIST: `${DOMAIN}/products/:shopId/variation/list/`,
    API_PRODUCT_DETAILS: `${DOMAIN}/products/:productId/details/`,
    API_PRODUCT_TITLE_UPDATE: `${DOMAIN}/products/:productId/update/title`,
    API_PRODUCT_CAT_UPDATE: `${DOMAIN}/products/:productId/update/category`,
    API_PRODUCT_SKU_UPDATE: `${DOMAIN}/products/:productId/update/sku`,
    API_PRODUCT_DES_UPDATE: `${DOMAIN}/products/:productId/update/description`,
    API_PRODUCT_PRICE_UPDATE: `${DOMAIN}/products/:productId/update/price`,
    API_PRODUCT_PRICE_OFFER_UPDATE: `${DOMAIN}/products/:productId/update/price-offer`,
    API_PRODUCT_IMG_UPDATE: `${DOMAIN}/products/:productId/update/images`,
    API_PRODUCT_REATURE_IMG_UPDATE: `${DOMAIN}/products/:productId/update/feature/images`,
    API_PRODUCT_VARIATION_HEAD_UPDATE: `${DOMAIN}/products/:productId/update/variation-header`,
    API_PRODUCT_PUBLISH_UPDATE: `${DOMAIN}/products/:productId/update/publish`,
    API_PRODUCT_PROPERTY_UPDATE: `${DOMAIN}/products/:productId/update/property`,
    API_PRODUCT_VARIANT_STOCK_UPDATE: `${DOMAIN}/products/:productId/update/variant-stock`,
    API_PRODUCT_VARIANT_PRICE_UPDATE: `${DOMAIN}/products/:productId/update/variant-price`,
    API_PRODUCT_FEATURE: `${DOMAIN}/products/:shopId/feature`,
    API_PRODUCT_FEATURE_UPDATE: `${DOMAIN}/products/:shopId/feature/update`,
    API_PRODUCT_FEATURE_REMOVE: `${DOMAIN}/products/:shopId/feature/:featureId/remove`,
    API_PRODUCT_CACHE: `${DOMAIN}/products/:productId/cache/update`,
    // --Image Upload
    API_IMAGE_UPLOAD: `${DOMAIN}/images/upload`,

    // --Category Paths
    API_CATEGORY_LIST: `${DOMAIN}/products/category`,
    API_CATEGORY: `${DOMAIN}/products/:shopId/category`,
    API_CATEGORY_CREATE: `${DOMAIN}/products/:shopId/category/create`,

    // --Shop Module
    API_SHOP_LIST: `${DOMAIN}/shop/list`,
    API_SHOP_CREATE: `${DOMAIN}/shop/create`,
    API_SHOP_DETAIL: `${DOMAIN}/shop/:shopId/detail`,
    API_SHOP_UPDATE_TITLE: `${DOMAIN}/shop/:shopId/update/title`,
    API_SHOP_UPDATE_LOCALE: `${DOMAIN}/shop/:shopId/update/locale`,
    API_SHOP_UPDATE_LIVE: `${DOMAIN}/shop/:shopId/update/live`,
    API_SHOP_UPDATE_CONTACT: `${DOMAIN}/shop/:shopId/update/contact`,
    API_SHOP_UPDATE_DETAIL: `${DOMAIN}/shop/:shopId/update/detail`,
    API_SHOP_UPDATE_ADDRESS: `${DOMAIN}/shop/:shopId/update/address`,
    API_SHOP_UPDATE_IMAGE: `${DOMAIN}/shop/:shopId/update/image`,
    API_SHOP_UPDATE_BANNER: `${DOMAIN}/shop/:shopId/update/banner`,
    API_SHOP_UPDATE_SOCIAL: `${DOMAIN}/shop/:shopId/update/social`,
    API_SHOP_SLIDER_IMAGE: `${DOMAIN}/shop/:shopId/slider/info`,
    API_SHOP_SLIDER_IMAGE_REMOVE: `${DOMAIN}/shop/:shopId/slider/:imgId/remove`,
    API_SHOP_SLIDER_IMAGE_UPLOAD: `${DOMAIN}/shop/slider/upload`,
    API_SHOP_WELCOME_IMAGE: `${DOMAIN}/shop/:shopId/welcome/banner/info`,
    API_SHOP_WELCOME_IMAGE_UPLOAD: `${DOMAIN}/shop/:shopId/welcome/banner/upload`,
    API_SHOP_WELCOME_IMAGE_REMOVE: `${DOMAIN}/shop/:shopId/welcome/banner/:imgId/remove`,

    // --Campaign Module
    API_CAMPAIGN: `${DOMAIN}/campaign/:shopId/list`,
    API_CAMPAIGN_DETAILS: `${DOMAIN}/campaign/:campId/details`,
    API_CAMPAIGN_CREATE: `${DOMAIN}/campaign/:shopId/create`,
    API_CAMPAIGN_UPDATE_TITLE: `${DOMAIN}/campaign/:campId/update/title`,
    API_CAMPAIGN_UPDATE_DURATION: `${DOMAIN}/campaign/:campId/update/duration`,
    API_CAMPAIGN_UPDATE_DISCOUNT: `${DOMAIN}/campaign/:campId/update/discount`,
    API_CAMPAIGN_UPDATE_DESCRUPTION: `${DOMAIN}/campaign/:campId/update/description`,
    API_CAMPAIGN_UPDATE_IMAGE: `${DOMAIN}/campaign/:campId/update/image`,
    API_CAMPAIGN_ADD_PRODUCTS: `${DOMAIN}/campaign/:campId/update/products`,

    // --Support Module
    API_SUPPORT: `${DOMAIN}/support/:shopId/list`,
    API_SUPPORT_TICKET_MESSAGE: `${DOMAIN}/support/:shopId/ticket/:ticketId/message`,
    API_SUPPORT_TICKET_MESSAGE_CREATE: `${DOMAIN}/support/:shopId/ticket/message/create`,
    API_SUPPORT_TICKET_COMPLETE: `${DOMAIN}/support/:shopId/ticket/:ticketId/complete`,

    // Order Module
    API_ORDERS: `${DOMAIN}/order/:shopId/list`,
    API_ORDERS_RECENT: `${DOMAIN}/order/:shopId/recent`,
    API_ORDERS_CONFIRM: `${DOMAIN}/order/:shopId/:orderId/confirm`,
    API_ORDERS_DETAIL: `${DOMAIN}/order/:shopId/:orderId/detail`,
    API_ORDERS_CASH_PAYMENT: `${DOMAIN}/order/:shopId/:orderId/cash-payment`,

    // Corn Jobs
    API_SETTING_CORN_JOBS: `${DOMAIN}/setting/:shopId/corn-jobs`,
    API_SETTING_CORN_JOB_DATA: `${DOMAIN}/setting/:shopId/corn-job/:cornId`,
    API_SETTING_CORN_JOB_CREATE: `${DOMAIN}/setting/:shopId/corn-job/create`,
    API_SETTING_CORN_JOB_UPDATE: `${DOMAIN}/setting/:shopId/corn-job/:cornId/update`,
    // Setting User
    API_SETTING_USER_LIST: `${DOMAIN}/setting/:shopId/users`,
    API_SETTING_USER_CREATE: `${DOMAIN}/setting/:shopId/user/create`,
    API_SETTING_USER_DATA: `${DOMAIN}/setting/:shopId/user/:userId`,
    API_SETTING_USER_UPDATE: `${DOMAIN}/setting/:shopId/user/:userId/update`,
    API_SETTING_USER_UPDATE_PASSWORD: `${DOMAIN}/setting/:shopId/user/:userId/update/password`,
    API_SETTING_USER_UPDATE_ROLE: `${DOMAIN}/setting/:shopId/user/:userId/update/role`,
    API_SETTING_USER_REMOVE_ROLE: `${DOMAIN}/setting/:shopId/user/:userId/remove/role`,
    API_SETTING_NOTIFICATION: `${DOMAIN}/setting/:shopId/notification/info`,
    API_SETTING_NOTIFICATION_SET: `${DOMAIN}/setting/:shopId/notification/set`,
    API_SETTING_NOTIFICATION_TEMP_SET: `${DOMAIN}/setting/:shopId/notification/template/set`,
    API_SETTING_GATEWAY_SET: `${DOMAIN}/setting/:shopId/gateway/set`,

    API_SETTING_PAYMENT: `${DOMAIN}/setting/:shopId/payment/info`,
    API_SETTING_PAYMENT_CONFIG: `${DOMAIN}/setting/:shopId/payment/config`,
    API_SETTING_PAYMENT_CONFIG_PRO: `${DOMAIN}/setting/:shopId/payment/config/production`,
    API_SETTING_PAYMENT_SET: `${DOMAIN}/setting/:shopId/payment/info/set`,
    API_SETTING_PAYMENT_BASIC_SET: `${DOMAIN}/setting/:shopId/payment/info/basic-set`,
    API_SETTING_PAYMENT_CURRENCY_SET: `${DOMAIN}/setting/:shopId/payment/currency/set`,
    API_SETTING_PAYMENT_CURRENCY_REMOVE: `${DOMAIN}/setting/:shopId/payment/currency/:currencyId/remove`,
    API_SETTING_PAYMENT_CURRENCY_UPDATE: `${DOMAIN}/setting/:shopId/payment/currency/:currencyId/update`,
    API_SETTING_PAYMENT_OFFLINE: `${DOMAIN}/setting/:shopId/payment/config/offline`,

    // Setting Permission
    API_SETTING_PERMISSION_CREATE: `${DOMAIN}/setting/:shopId/permission/create`,

    // Setting Shipping Charge
    API_SETTING_SHIPPING_CHARGE: `${DOMAIN}/setting/:shopId/shipping-charge/info`,
    API_SETTING_SHIPPING_CHARGE_CREATE: `${DOMAIN}/setting/:shopId/shipping-charge/create`,
    API_SETTING_SHIPPING_CHARGE_UPDATE: `${DOMAIN}/setting/:shopId/shipping-charge/:shippingId/update`,

    // Corn Jobs Run
    API_RUN_CORN_JOBS: `${PUBLIC_DOMAIN}/cron/five-min`,
    // Customer User
    API_CUSTOMER_LIST: `${DOMAIN}/customer/:shopId/list`,
    API_CUSTOMER_CREATE: `${DOMAIN}/customer/:shopId/create`,
    API_CUSTOMER_DETAIL: `${DOMAIN}/customer/:shopId/:customId/detail`,
    API_CUSTOMER_UPDATE: `${DOMAIN}/customer/:shopId/:customId/update`,
    // Reward Module
    API_REWARD_INFO: `${DOMAIN}/reward/:shopId/info`,
    API_REWARD_DATA: `${DOMAIN}/reward/:shopId/:rewardId/data`,
    API_REWARD_UPDATE: `${DOMAIN}/reward/:shopId/update`,
    API_REWARD_ACTIVE: `${DOMAIN}/reward/:shopId/active`,
    API_REWARD_PRODUCT: `${DOMAIN}/reward/:shopId/product`,
    API_REWARD_PRODUCT_CREATE: `${DOMAIN}/reward/:shopId/product/create`,
    API_REWARD_PRODUCT_UPDATE: `${DOMAIN}/reward/:shopId/product/:rewardId/update`,
    API_REWARD_OPTION: `${DOMAIN}/reward/:shopId/info/option`,
    API_REWARD_OPTION_CREATE: `${DOMAIN}/reward/:shopId/info/option/create`,
    API_REWARD_OPTION_UPDATE: `${DOMAIN}/reward/:shopId/info/option/:rwId/update`,

    // Stock Module
    API_STOCK_LIST: `${DOMAIN}/stock/:shopId/list`,
    API_STOCK_PRODUCT_CATEGORY: `${DOMAIN}/stock/:shopId/product/:categoryId/category/list`,
    API_STOCK_ACTIVE: `${DOMAIN}/stock/:shopId/active`,
    API_STOCK_PRODUCT: `${DOMAIN}/stock/:shopId/product`,
    API_STOCK_PRODUCT_CREATE: `${DOMAIN}/stock/:shopId/product/create`,
    API_STOCK_PRODUCT_UPDATE: `${DOMAIN}/stock/:shopId/product/:rewardId/update`,
};

export default ConfigApi;
